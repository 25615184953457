// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-v-1-a-js": () => import("./../../../src/pages/v1a.js" /* webpackChunkName: "component---src-pages-v-1-a-js" */),
  "component---src-pages-v-1-b-js": () => import("./../../../src/pages/v1b.js" /* webpackChunkName: "component---src-pages-v-1-b-js" */),
  "component---src-pages-v-1-js": () => import("./../../../src/pages/v1.js" /* webpackChunkName: "component---src-pages-v-1-js" */),
  "component---src-pages-v-2-js": () => import("./../../../src/pages/v2.js" /* webpackChunkName: "component---src-pages-v-2-js" */),
  "component---src-pages-v-3-js": () => import("./../../../src/pages/v3.js" /* webpackChunkName: "component---src-pages-v-3-js" */),
  "component---src-pages-v-4-js": () => import("./../../../src/pages/v4.js" /* webpackChunkName: "component---src-pages-v-4-js" */),
  "component---src-pages-v-5-js": () => import("./../../../src/pages/v5.js" /* webpackChunkName: "component---src-pages-v-5-js" */),
  "component---src-pages-v-6-js": () => import("./../../../src/pages/v6.js" /* webpackChunkName: "component---src-pages-v-6-js" */)
}

